<template>
    <div class="page-mode mb-12 page-shipments">
        <template>
            <div v-if="!loading" class="flex flex__align-center -translate-y-10">
                <modal-field
                    v-if="statisticsData.confirmed"
                    label="AWAITING PICK UP"
                >
                    <div class="flex flex__align-end">
                        <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.625 2.5H10.5C10.5 1.12109 9.37891 0 8 0C6.62109 0 5.5 1.12109 5.5 2.5H2.375C1.33984 2.5 0.5 3.33984 0.5 4.375V18.125C0.5 19.1602 1.33984 20 2.375 20H13.625C14.6602 20 15.5 19.1602 15.5 18.125V4.375C15.5 3.33984 14.6602 2.5 13.625 2.5ZM8 1.5625C8.51953 1.5625 8.9375 1.98047 8.9375 2.5C8.9375 3.01953 8.51953 3.4375 8 3.4375C7.48047 3.4375 7.0625 3.01953 7.0625 2.5C7.0625 1.98047 7.48047 1.5625 8 1.5625ZM12.7344 10.6172L7.14844 16.1562C6.96484 16.3398 6.66797 16.3359 6.48438 16.1523L3.25781 12.8984C3.07422 12.7148 3.07812 12.418 3.26172 12.2344L4.37109 11.1328C4.55469 10.9492 4.85156 10.9531 5.03516 11.1367L6.83203 12.9492L10.9727 8.83984C11.1562 8.65625 11.4531 8.66016 11.6367 8.84375L12.7383 9.95312C12.9219 10.1406 12.918 10.4336 12.7344 10.6172Z" fill="black" fill-opacity="0.5"/>
                        </svg>
                        <div class="text-xl ml-3 lh-16">
                            {{ statisticsData.confirmed.shipments }} shpmts
                            <span v-if="statisticsData.confirmed.xceptions" @click="updateDashboardItem({ label: 'Confirmed', value: 'CONFIRMED' })" class="color-pink cursor-pointer">
                                {{ statisticsData.confirmed.xceptions }} xcs
                            </span>
                            <span v-else class="color-green">
                                {{ statisticsData.confirmed.xceptions }} xcs
                            </span>
                        </div>
                    </div>
                </modal-field>

                <modal-field
                    v-if="statisticsData.pickup"
                    label="AWAITING CONFIRMATION"
                    class="ml-12 lh-16"
                >
                    <div class="flex flex__align-center">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.5481 5.025L15.8633 7C15.8779 7.05022 15.8828 7.10184 15.8877 7.15393C15.8901 7.1796 15.8925 7.20546 15.8961 7.23125H9.36914L9.43751 4H13.3039C13.8699 4 14.3703 4.4125 14.5481 5.025ZM4.69611 4H8.56251L8.49414 7.23125H1.96719C1.9708 7.20544 1.97322 7.17963 1.97563 7.15393C1.98051 7.10184 1.98535 7.05022 2 7L3.45197 5.025C3.6297 4.4125 4.13009 4 4.69611 4Z" fill="black" fill-opacity="0.5"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M2.00001 18.5L1.93164 8.23125H15.9316L15.9625 12.8709H9.5C9.15621 12.8709 8.87492 13.1254 8.87492 13.4363V14.5672C8.87492 14.8782 9.15621 15.1326 9.5 15.1326H15.9776L16 18.5C16 19.3281 15.4121 20 14.6875 20H3.31251C2.5879 20 2.00001 19.3281 2.00001 18.5Z" fill="black" fill-opacity="0.5"/>
                            <path d="M18.0013 10.5669V12.8709H15.9625L15.9776 15.1326H17.9974V17.4331C17.9974 17.9385 18.6733 18.1894 19.0678 17.8325L22.8066 14.4258C23.0645 14.1926 23.0645 13.8145 22.8066 13.5777L19.0718 10.1675C18.6772 9.8106 18.0013 10.0615 18.0013 10.5669Z" fill="black" fill-opacity="0.5"/>
                        </svg>

                        <div class="text-xl ml-3">
                            {{ statisticsData.pickup.shipments }} shpmts
                            <span v-if="statisticsData.pickup.xceptions" @click="updateDashboardItem({ label: 'Picked up', value: 'PICKED_UP' })" class="color-pink cursor-pointer">
                                {{ statisticsData.pickup.xceptions }} xcs
                            </span>
                            <span v-else class="color-green">
                                {{ statisticsData.pickup.xceptions }} xcs
                            </span>
                        </div>
                    </div>
                </modal-field>

                <modal-field
                    v-if="statisticsData.transit"
                    label="IN TRANSIT"
                    class="ml-12"
                >
                    <div class="flex flex__align-center">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M21.5 15H21V11.6219C21 11.225 20.8406 10.8438 20.5594 10.5625L17.4375 7.44062C17.1562 7.15937 16.775 7 16.3781 7H15V5.5C15 4.67188 14.3281 4 13.5 4H3.5C2.67188 4 2 4.67188 2 5.5V15.5C2 16.3281 2.67188 17 3.5 17H4C4 18.6562 5.34375 20 7 20C8.65625 20 10 18.6562 10 17H14C14 18.6562 15.3438 20 17 20C18.6562 20 20 18.6562 20 17H21.5C21.775 17 22 16.775 22 16.5V15.5C22 15.225 21.775 15 21.5 15ZM7 18.5C6.17188 18.5 5.5 17.8281 5.5 17C5.5 16.1719 6.17188 15.5 7 15.5C7.82812 15.5 8.5 16.1719 8.5 17C8.5 17.8281 7.82812 18.5 7 18.5ZM17 18.5C16.1719 18.5 15.5 17.8281 15.5 17C15.5 16.1719 16.1719 15.5 17 15.5C17.8281 15.5 18.5 16.1719 18.5 17C18.5 17.8281 17.8281 18.5 17 18.5ZM19.5 12H15V8.5H16.3781L19.5 11.6219V12Z" fill="black" fill-opacity="0.5"/>
                        </svg>

                        <div class="text-xl ml-3 lh-16">
                            {{ statisticsData.transit.shipments }} shpmts
                            <span v-if="statisticsData.transit.xceptions" @click="updateDashboardItem({ label: 'In transit', value: 'IN_TRANSIT' })" class="color-pink cursor-pointer">
                                {{ statisticsData.transit.xceptions }} xcs
                            </span>
                            <span v-else class="color-green">
                                {{ statisticsData.transit.xceptions }} xcs
                            </span>
                        </div>
                    </div>
                </modal-field>
            </div>
            <div v-else class="flex flex__align-center -translate-y-10">
                <spinning-clock class="w-6 h-6" />
            </div>
        </template>

        <card body-class="overflow-visible pb-0" class="border-radius--l">
            <div class="flex flex__column w__100-p shipments-filters-drag" :class="{'shipments-filters-drag--active': asFilterControl}">
                <div class="flex flex__justify-between w__100-p shipments-hot-panel">
                    <div class="flex flex__grow">
                        <draggable
                            v-if="initDragg"
                            :list="filterHeader"
                            draggable=".item"
                            handle=".text-draggable"
                            :group="hotGroup"
                            class="flex flex__grow shipments-hot-block shipments-drag-zone"
                            :options="{disabled: !asFilterControl}"
                            :class="{'shipments-drag-zone--active': asFilterControl && asFilterDrag }"
                            @start="onStart"
                            @end="asFilterDrag = false"
                        >
                            <filter-item
                                v-model="generateFilter(item)[item.model]"

                                v-for="item in filterHeader"
                                :key="item.template"

                                :template="item.template"
                                :type-form="item.typeForm"
                                :adminRoute="adminRoute"
                                :user="user"
                                :options="options"
                                :organizations="organizations"
                                :loaded-range="loadedRange"
                                :datesTrigger="datesTrigger"

                                :filter="item.filter"
                                :filterModel="item.filterModel"
                                :optionsName="item.optionsName"
                                :owner_list="owner_list"
                                :createdComponents="createdComponents"

                                :branding="item.branding"
                                :group-label="user.level === 'super' ? 'name' : ''"
                                :group-select="item.groupSelect"
                                :group-values="user.level === 'super' ? 'sites' : ''"
                                :class="[item.className, { 'mt-5': item.typeForm === 'checkbox' }]"
                                :name="item.name"
                                :label="item.label"
                                :placeholder="item.placeholder"
                                :track-by="item.trackBy"
                                :multiple="item.multiple"
                                :remove-all-item="item.removeAllItem"
                                :remove-default-value="item.removeDefaultValue"
                                :initAll="item.initAll"

                                class="item"
                            />
                        </draggable>
                    </div>

                    <div v-if="!asFilterControl" class="flex flex__align-center shipments-hot-panel__bar">
                        <icon name="icons/settings" title="Show all filters" @click="showAdvanced = !showAdvanced" />

                        <icon name="icons/filter-control" title="Filter control" class="ml-4" @click="onFilterControl" />

                        <confirm @confirmed="resetData" confirm-text="Reset form">
                            <icon name="icons/reset" title="Reset form" class="hover:text-red-500 icon-btn ml-4" />
                        </confirm>
                    </div>

                    <div v-else class="flex flex__align-center shipments-hot-panel__bar">
                        <p class="no-wrap">Save this layout?</p>

                        <div class="w-1 h-6 ml-4 border-r" />

                        <icon name="icons/check" key="shipments-check" title="Save filters" class="ml-4" @click="saveFilterControl" />

                        <icon name="icons/close" key="shipments-close" title="Reset filters" type="button" class="ml-4" @click="cancelFilterControl" />
                    </div>
                </div>

                <collapse-transition>
                    <div class="flex flex-col w-full shipments-all-filters" v-show="showAdvanced">
                        <h5 class="mt-3 text-header-secondary">Advanced filters</h5>

                        <div class="flex flex__justify-between mt-4">
                            <div class="flex__grow pr-4">
                                <draggable
                                    v-if="initDragg"
                                    :list="filterSelects"
                                    draggable=".item"
                                    handle=".text-draggable"
                                    group="a"
                                    class="flex flex__wrap w-full shipments-selects-block shipments-drag-zone"
                                    :options="{disabled: !asFilterControl}"
                                    :class="{'shipments-drag-zone--active': asFilterControl && asFilterDrag && hotGroup === 'a'}"
                                    @start="hotGroup = 'a'; asFilterDrag = true"
                                    @end="asFilterDrag = false"
                                >
                                    <filter-item
                                        v-model="generateFilter(item)[item.model]"

                                        v-for="item in filterSelects"
                                        :key="item.template"

                                        :template="item.template"
                                        :type-form="item.typeForm"
                                        :adminRoute="adminRoute"
                                        :user="user"
                                        :options="options"
                                        :organizations="organizations"
                                        :loaded-range="loadedRange"
                                        :datesTrigger="datesTrigger"

                                        :filter="item.filter"
                                        :filterModel="item.filterModel"
                                        :optionsName="item.optionsName"
                                        :owner_list="owner_list"
                                        :createdComponents="createdComponents"

                                        :branding="item.branding"
                                        :group-label="user.level === 'super' ? 'name' : ''"
                                        :group-select="item.groupSelect"
                                        :group-values="user.level === 'super' ? 'sites' : ''"
                                        :class="item.className"
                                        :name="item.name"
                                        :label="item.label"
                                        :placeholder="item.placeholder"
                                        :track-by="item.trackBy"
                                        :multiple="item.multiple"
                                        :remove-all-item="item.removeAllItem"
                                        :remove-default-value="item.removeDefaultValue"
                                        :initAll="item.initAll"
                                        :max-selectable="item.maxSelectable"
                                        :disabled-select-all="item.disabledSelectAll"

                                        class="item mr-4 mb-4"
                                    />
                                </draggable>
                            </div>

                            <div
                                class="relative shipments-switch-block-wrapper shipments-drag-zone"
                                style="width: 150px; min-width: 150px"
                                :class="{'shipments-drag-zone--active': asFilterControl && asFilterDrag && hotGroup === 'b'}"
                            >
                                <draggable
                                    v-if="initDragg"
                                    :list="filterSwitch"
                                    draggable=".item"
                                    handle=".text-draggable"
                                    group="b"
                                    class="shipments-switch-block" 
                                    :options="{disabled: !asFilterControl}"
                                    @start="hotGroup = 'b'; asFilterDrag = true"
                                    @end="asFilterDrag = false"
                                >
                                    <filter-item
                                        v-model="generateFilter(item)[item.model]"

                                        v-for="item in filterSwitch"
                                        :key="item.template"

                                        :template="item.template"
                                        :type-form="item.typeForm"
                                        :placeholder="item.placeholder"
                                        :adminRoute="adminRoute"
                                        :user="user"
                                        :options="options"
                                        :organizations="organizations"
                                        :loaded-range="loadedRange"
                                        :datesTrigger="datesTrigger"

                                        :filter="item.filter"
                                        :filterModel="item.filterModel"
                                        :optionsName="item.optionsName"
                                        :owner_list="owner_list"
                                        :createdComponents="createdComponents"

                                        :branding="item.branding"
                                        :group-label="user.level === 'super' ? 'name' : ''"
                                        :group-select="item.groupSelect"
                                        :group-values="user.level === 'super' ? 'sites' : ''"
                                        :class="item.className"
                                        :name="item.name"
                                        :label="item.label"
                                        :track-by="item.trackBy"
                                        :multiple="item.multiple"
                                        :remove-all-item="item.removeAllItem"
                                        :remove-default-value="item.removeDefaultValue"
                                        :initAll="item.initAll"
                                        :disabled="item.disabled"

                                        class="item"
                                    />
                                </draggable>
                            </div>
                        </div>
                    </div>
                </collapse-transition>
            </div>
            <!-- <pre>{{ options }}</pre> -->
        </card>
        <card body-class="p-0 -mx-6 overflow-visible page-mode-table-body" class="shipments-table page-mode-table bg-transparent">
            <shipments-list
                :branding="! adminRoute"
                :class="{'admin-table': adminRoute }"
                :columns="columns"
                :filter-params="filterParams"
                :id="tableRef"
                :loading="loadingConsignments || sitesLoading"
                :ref="tableRef"
                :rows="consignments"
                :tools="false"
                :panel="true"
                :saved-rows="savedRows()"
                :right-tools="false"
                :selected="checkedRows.length"
                @check-row="rowChecked"
                @row-click="rowClicked"
                paginate
                checkable
                :endpoint="$apiUrl.consignments.base"
                query-params
                :draggable="true"
                row-key="uuid"
                :sort-key="shipmentsFilter && shipmentsFilter.sort_key ? shipmentsFilter.sort_key : 'ordered_at'"
                :sort-order="shipmentsFilter && shipmentsFilter.sort_order ? Number(shipmentsFilter.sort_order) : -1"
                class="t-draggable page-mode-table-content"
                :saved-data="shipmentsFilter"
                custom-checkable
                @table-rows="getTableRows"
                @updated-columns="updatedColumns"
                @updated-page-size="updatedPageSize"
                @updated-page="updatedPage"
                @updated-sort-key="updateSortKey"
                @updated-sort-order="updateSortOrder"
                @send-response="pageLoad = true"
            >
                <template slot="panel_right">
                    <div class="relative flex flex__align-center mr-4">
                        <icon name="icons/save" title="Save query" class="ml-4 outline-default" @click="showSaveQueryModal = true" />

                        <icon name="icons/load-query" title="Load query" class="ml-4 outline-default" @click="showLoadQueryModal = true" />

                        <icon name="icons/forward" title="Share the filter link" class="ml-4 outline-default" @click="shareFilterLink" /> 
                    </div>
                </template>

                <template slot="panel">
                    <div class="relative flex items tools flex__align-center">
                        <checkbox
                            v-model="checkedAll"
                            :class="{'no-branding': !adminRoute}"
                            class="ml-1 mr-2"
                            @change="rowCheckedAll"
                        />

                        <icon
                            name="icons/bookmark-black"
                            class="outline-default mr-4"
                            title="Bookmark"
                            :disabled="toggleTableTools || checkedRows.length == 0"
                            @click="saveConsignments"
                        />

                        <icon
                            name="icons/bookmark"
                            class="outline-default mr-4"
                            :disabled="toggleTableTools || checkedRows.length == 0"
                            title="Remove bookmark"
                            @click="removeSavedConsignments"
                        />
                    </div>
                </template>

                <template slot="export">
                    <div class="relative flex flex__align-center">
                        <icon
                            name="icons/download"
                            :disabled="toggleTableTools"
                            @click="showExportModal = true"
                            class="outline-default border-color mr-4"
                            title="Export"
                        >
                            Export
                            <span
                                v-if="checkedRows.length"
                                v-text="`(${checkedRows.length})`"
                                class="ml-1 mr-2"
                            />
                        </icon>
                    </div>
                    <freight-modal v-if="showFreightModal" :consignments="checkedRows" />
                </template>
            </shipments-list>
        </card>

        <modal class="load-query-modal" :show="showLoadQueryModal" size="md" @hide="showLoadQueryModal = false">
            <h2 slot="header">Load query</h2>
            <div class="p-4 pb-24" slot="body">
                <div
                    :class="{'border-t': index > 0}"
                    :key="index"
                    class="flex items-center w-full pt-2 mb-2"
                    v-for="(query, index) in loadedQueries"
                >
                    <span class="capitalize">{{ query.title }}</span>
                    <span class="ml-3 text-sm text-gray-600">Created {{ query.created_at }}</span>

                    <confirm @confirmed="loadQuery(query)" class="ml-auto" confirm-text="Yes">
                        <button class="ml-auto btn btn-sm">Load</button>
                    </confirm>

                    <button class="ml-3 btn btn-sm btn-red no-branding" @click="removeQuery(query)">Delete</button>
                </div>
            </div>
        </modal>

        <modal :loading="savingQuery" :show="showSaveQueryModal" size="md" @hide="showSaveQueryModal = false">
            <h2 slot="header">Save query</h2>
            <div class="p-4" slot="body">
                <modal-field label="Query name:">
                    <input type="text" v-model="saveQuery.title" />
                </modal-field>
            </div>

            <div class="flex w-full" slot="footer">
                <button
                    @click="showSaveQueryModal = false"
                    class="ml-auto mr-4 text-sm text-red-500"
                >Cancel</button>
                <button @click="postSaveQuery" class="btn">Save</button>
            </div>
        </modal>

        <export-modal :show="showExportModal" :columns="columnsToExport" @hide="showExportModal = false" @export="toExcel" />
    </div>
</template>

<script>
import filterOptions from '~/data/consignments-filter-options';
import axios from 'axios';
import moment from 'moment';
import Cookies from 'js-cookie';
import Draggable from 'vuedraggable';
import { serializeParams } from '~/components/vtable/utils';
import FilterItem from '~/components/ShipmentsAdmin/FilterItem';
import ShipmentsList from '~/components/Shipments/List';
import { CollapseTransition } from '@ivanv/vue-collapse-transition';
import { prepareFiltersForSave } from '~/utils/filter.js';
import ExportModal from '~/components/Shipments/ExportModal';
import FreightModal from '~/components/Shipments/FreightModal';
import { replacePriceExportColumn } from '~/utils/replacePriceExportColumn';

function defaultFilterOptions() {
    return {
        date_between: [],
        owner_site_from_source: [],
        transport_mode: [],
        consignor_name: [],
        consignor_countrycode: [],
        consignor_city: [],
        consignee_name: [],
        consignee_countrycode: [],
        consignee_city: [],
        pickup_address: [],
        delivery_address: [],
        carrier_from_source: [],
        purchase_orders: [],
        carrier_service_id: [],
        duplicates_by: [],
        shipment_status: [],
        exception_at: [],
        exception_details: [],
        carrier_service_from_source: [],
        carrier_ids: [],
        carrier_service_ids: [],
        direction: filterOptions.direction,
    }
}

const filterCategory = 'shipments';

export default {
    name: 'Shipments',
    components: {
        CollapseTransition,
        Draggable,
        FilterItem,
        ShipmentsList,
        ExportModal,
        FreightModal,
    },

    metaInfo() {
        return { title: 'Shipments' }
    },
    data: () => ({
        showExportModal: false,
        filtersPositionFilterId: null,
        initDragg: false,
        createdComponents: false,
        hotGroup: 'a',
        asFilterControl: false,
        asFilterDrag: false,
        checkedAll: false,
        shipmentsFilter: null,
        pageLoad: false,
        isResetData: false,
        filterHeader: [
            {
                template: 'by_reference',
                typeForm: 'input',
                name: 'Consignment # / Reference #',
                model: 'by_reference',
                filter: true,
            },
            {
                template: 'carriers',
                typeForm: 'multi-select',
                name: 'Carriers',
                optionsName: 'carrier_ids',
                model: 'carrier_ids',
                label: 'name',
                trackBy: 'id',
            },
            {
                template: 'transport_mode',
                typeForm: 'multi-select',
                name: 'Transport mode(s)',
                optionsName: 'transport_mode',
                model: 'transport_mode',
            },
            {
                template: 'date',
                typeForm: 'date',
                name: 'Date',
                fullList: true,
                model: 'range',
                filter: true,
            },
            {
                template: 'is-in-progress',
                typeForm: 'checkbox',
                name: 'Active shipments',
                model: 'is_in_progress',
                filter: true,
            },
            {
                template: 'has_bookmarks',
                typeForm: 'checkbox',
                name: 'Bookmarked',
                model: 'has_bookmarks',
                filter: true,
            },
            {
                template: 'exceptions',
                typeForm: 'checkbox',
                name: 'Exceptions',
                model: 'has_exception',
                filter: true,
            },
        ],
        filterSelects: [
            {
                template: 'carrier_service_ids',
                typeForm: 'multi-select',
                name: 'Carrier service',
                optionsName: 'carrier_service_ids',
                model: 'carrier_service_ids',
                label: 'name',
                trackBy: 'id',
            },
            {
                template: 'shipment-status',
                typeForm: 'multi-select-colors',
                name: 'Shipment status',
                optionsName: 'shipment_status',
                label: 'label',
                trackBy: 'value',
                model: 'statuses',
            },
            {
                template: 'exception-details',
                typeForm: 'multi-select',
                name: 'Exception details',
                optionsName: 'exception_details',
                label: 'label',
                trackBy: 'value',
                model: 'exception_details',
            },
            {
                template: 'current-etd-date',
                typeForm: 'date',
                name: 'Estimated Departure Date',
                fullList: true,
                model: 'etd_date_between',
                filter: true,
                removeDefaultValue: true,
            },
            {
                template: 'current-eta-date',
                typeForm: 'date',
                name: 'Estimated Delivery Date',
                fullList: true,
                model: 'eta_date_between',
                filter: true,
                removeDefaultValue: true,
            },
            {
                template: 'from-company',
                typeForm: 'multi-select',
                name: 'From company',
                optionsName: 'consignor_name',
                model: 'consignor_name',
                filter: true,
                maxSelectable: 10,
                disabledSelectAll: true,
            },
            {
                template: 'pickup_postcode',
                typeForm: 'input',
                name: 'Pickup ZIP',
                model: 'pickup_postcode',
                filter: true,
            },
            {
                template: 'consignor_pickup_countrycode',
                typeForm: 'multi-select',
                name: 'From country',
                optionsName: 'consignor_countrycode',
                model: 'consignor_pickup_countrycode',
                filter: true,
            },
            {
                template: 'consignor_city',
                typeForm: 'multi-select',
                name: 'From city',
                optionsName: 'consignor_city',
                model: 'consignor_city',
                filter: true,
            },
            {
                template: 'to-company',
                typeForm: 'multi-select',
                name: 'To company',
                optionsName: 'consignee_name',
                model: 'consignee_name',
                filter: true,
                maxSelectable: 10,
                disabledSelectAll: true,
            },
            {
                template: 'to_postcode',
                typeForm: 'input',
                name: 'Consignee ZIP',
                model: 'to_postcode',
                filter: true,
            },
            {
                template: 'delivery_postcode',
                typeForm: 'input',
                name: 'Delivery ZIP',
                model: 'delivery_postcode',
                filter: true,
            },
            {
                template: 'consignee_delivery_countrycode',
                typeForm: 'multi-select',
                name: 'To country',
                optionsName: 'consignee_countrycode',
                model: 'consignee_delivery_countrycode',
                filter: true,
            },
            {
                template: 'consignee_city',
                typeForm: 'multi-select',
                name: 'To city',
                optionsName: 'consignee_city',
                model: 'consignee_city',
                filter: true,
            },
            {
                template: 'source',
                typeForm: 'multi-select',
                name: 'Source',
                optionsName: 'source',
                model: 'source',
                label: 'name',
                trackBy: 'value',
                filter: true,
            },
            {
                template: 'filter-site-client',
                typeForm: 'multi-select',
                name: 'Filter site',
                optionsName: 'owner_site_from_source',
                model: 'owner_site_from_source',
                label: 'name',
                trackBy: 'id',
            },
            {
                template: 'direction',
                typeForm: 'multi-select',
                name: 'Direction',
                optionsName: 'direction',
                model: 'direction',
                label: 'name',
                trackBy: 'id',
                filter: true,
            },
        ],
        filterSwitch: [
            {
                template: 'is_time_definite',
                typeForm: 'checkbox',
                name: 'Time-definite',
                model: 'is_time_definite',
                filter: true,
            },
            {
                template: 'under_investigation',
                typeForm: 'checkbox',
                name: 'U. investigation',
                model: 'under_investigation',
                filter: true,
            },
            {
                template: 'egen_enabled',
                typeForm: 'checkbox',
                name: 'Incl. Egen transp.',
                model: 'egen_enabled',
                disabled: false,
                filter: true,
            },
            {
                template: 'include_shared',
                typeForm: 'checkbox',
                name: 'Incl. shared consignments',
                model: 'include_shared',
                disabled: false,
                filter: true,
            },
        ],
        copyFiltersDrag: {
            filterHeader: [],
            filterSelects: [],
            filterSwitch: [],
        },
        datesTrigger: 0,
        applications: [],
        allRowsChecked: false,
        toggleTableTools: false,
        showAdvanced: false,
        showSaveQueryModal: false,
        showLoadQueryModal: false,
        showSaveTemplatesModal: false,
        showLoadTemplatesModal: false,
        savingQuery: false,
        loadedQueries: null,
        loadedTemplates: null,
        loadedRange: false,
        contactModal: false,
        ownerOptions: [],
        siteOptions: [],
        organizations: [],
        selectedSite: [],
        statuses: [],
        carrier_service_id: [],
        exception_at: [],
        carrier_ids: [],
        owner_site_from_source: [],
        direction: [],
        carrier_service_ids: [],
        transport_mode: [],
        exception_details: [],
        props_exception_details: [],
        has_exception: 0,
        generateEmailHide: false,
        generateEmailData: {},
        commentHide: false,
        setTimeHide: false,
        setTimeValue: { id: '', value: '' },
        commentId: null,
        sendInformationHide: false,
        sendInformationData: {},
        statisticsData: {},
        owner_list: [],
        dictionary: {},
        watchTrigger: 0,
        handlerOwnerSite: true,
        order_no: '', 
        to_postcode: '',
        consignment_no: '',
        from_postcode: '',
        activeShipment: {
            data: {}
        },
        showEditShipmentModal: false,
        contact: {
            subject: null,
            message: null,
            recipient: 'support@vchain.se',
            type: 'contact',
            sending: false,
            withError: false,
            sent: '',
        },
        additionalQueryParams: [],
        filters: {
            range: 'last_month',
            etd_date_between: {
                startDate: '',
                endDate: ''
            },
            eta_date_between: {
                startDate: '',
                endDate: ''
            },
            by_reference: null,
            date_between: null,
            status: null,
            carrier_service_name: null,
            transport_mode: null,
            carrier_service_from_source: null,
            duplicates_by: null,
            consignor_name: null,
            consignor_countrycode: null,
            consignor_city: null,
            consignee_name: null,
            consignee_countrycode: null,
            consignee_city: null,
            pickup_address: null,
            delivery_address: null,
            carrier_from_source: null,
            consignment_no: null,
            order_no: null,
            owner_site_from_source: null,
            direction: null,
            owner_from_source: null,
            exception_at: null,
            carrier_ids: null,
            carrier_service_ids: null,
            exception_details: null,
            to_postcode: null,
            from_postcode: null,
            has_exception: 0,
            is_in_progress: 0,
            has_loading_meters: 0,
            is_heavy: 0,
            is_time_definite: 0,
            under_investigation: 0,
            egen_enabled: false,
            include_shared: true,
            source: null,
            consignor_pickup_countrycode: null,
            consignee_delivery_countrycode: null,
            is_archived: { name: 'Not archived', value: '0' },
            has_bookmarks: 0
        },
        defaults: {
            filters: {
                range: 'last_month',
                etd_date_between: {
                    startDate: '',
                    endDate: ''
                },
                eta_date_between: {
                    startDate: '',
                    endDate: ''
                },
                by_reference: null,
                date_between: null,
                status: null,
                carrier_service_id: null,
                carrier_service_from_source: null,
                transport_mode: null,
                duplicates_by: null,
                consignor_name: null,
                consignor_countrycode: null,
                consignor_city: null,
                consignee_name: null,
                consignee_countrycode: null,
                consignee_city: null,
                pickup_address: null,
                delivery_address: null,
                carrier_from_source: null,
                owner_site_from_source: null,
                direction: null,
                owner_from_source: null,
                exception_at: null,
                carrier_ids: null,
                carrier_service_ids: null,
                exception_details: null,
                to_postcode: null,
                from_postcode: null,
                has_exception: 0,
                is_in_progress: 0,
                has_loading_meters: 0,
                is_heavy: 0,
                is_time_definite: 0,
                under_investigation: 0,
                egen_enabled: false,
                include_shared: true,
                source: null,
                consignor_pickup_countrycode: null,
                consignee_delivery_countrycode: null,
                is_archived: { name: 'Not archived', value: '0' },
                has_bookmarks: 0
            }
        },
        options: {
            date_between: [],
            owner_site_from_source: [],
            direction: [],
            transport_mode: [],
            consignor_name: [],
            consignor_countrycode: [],
            consignor_city: [],
            consignee_name: [],
            consignee_countrycode: [],
            consignee_city: [],
            pickup_address: [],
            delivery_address: [],
            carrier_service_id: [],
            carrier_service_from_source: [],
            carrier_from_source: [],
            duplicates_by: [],
            owner_from_source: [],
            shipment_status: [],
            exception_at: [],
            exception_details: [],
            carrier_ids: [],
            carrier_service_ids: [],
            source: [],
        },

        checkedRows: [],
        saveQuery: {
            title: ''
        },
        saveTemplates: {
            templateData: null,
            title: ''
        },
        loadingConsignments: false,
        loadingFilters: false,
        consignments: [],
        filterParams: {},
        loading: false,
        tableRows: []
    }),
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.clearSelectedSite()
            vm.clearFilterOptions()
        })
    },
    computed: {
        vue() {
            return this;
        },
        pageSize() {
            return this.$refs[this.tableRef].table.pageSize;
        },
        checkedRowsLength() {
            return this.checkedRows.length
        },
        columns() {
            const columns = [
                {
                    title: 'Consignment no.',
                    key: 'consignment_no',
                    toggled: true,
                    sortable: true,
                    pin: false,
                    width: 'w-32'
                },
                {
                    title: 'Reference',
                    key: 'order_no',
                    toggled: true,
                    sortable: true,
                    pin: false,
                    width: 'w-32'
                },
                {
                    title: 'Site',
                    key: 'owner_site_from_source',
                    toggled: false,
                    sortable: true,
                    pin: false,
                    width: 'w-32'
                },
                {
                    title: 'Date',
                    key: 'ordered_at',
                    toggled: true,
                    width: 'w-24',
                    sortable: true
                },
                {
                    title: 'From',
                    key: 'consignor_name',
                    toggled: true,
                    width: 'w-48',
                    sortable: true
                },
                {
                    title: 'From address',
                    key: 'consignor_address',
                    toggled: false,
                    width: 'w-48',
                    sortable: true
                },
                {
                    title: 'From city',
                    key: 'consignor_city',
                    toggled: false,
                    width: 'w-48',
                    sortable: true
                },
                {
                    title: 'From post code',
                    key: 'consignor_postcode',
                    toggled: false,
                    width: 'w-20',
                    sortable: true
                },
                {
                    title: 'From country',
                    key: 'consignor_countrycode',
                    toggled: false,
                    width: 'w-12',
                    sortable: true
                },
                {
                    title: 'Senders reference',
                    key: 'order_no',
                    toggled: false,
                    sortable: true,
                    pin: false,
                    width: 'w-48'
                },
                {
                    title: 'Actual delivery date',
                    key: 'actual_time_delivery_at',
                    toggled: false,
                    sortable: true,
                    pin: false,
                    width: 'w-40'
                },
                {
                    title: 'Payer',
                    key: 'payer',
                    toggled: false,
                    sortable: true,
                    pin: false,
                    width: 'w-24'
                },
                {
                    title: 'Combiterms',
                    key: 'combiterms',
                    toggled: false,
                    sortable: true,
                    pin: false,
                    width: 'w-32'
                },
                {
                    title: 'Goods description',
                    key: 'goods_description',
                    toggled: false,
                    sortable: true,
                    pin: false,
                    width: 'w-40'
                },
                {
                    title: 'Pickup name',
                    key: 'pickup_name',
                    toggled: false,
                    sortable: true,
                    pin: false,
                    width: 'w-48',
                },
                {
                    title: 'Pickup address',
                    key: 'pickup_address',
                    toggled: false,
                    sortable: true,
                    pin: false,
                    width: 'w-48',
                },
                {
                    title: 'Pickup city',
                    key: 'pickup_city',
                    toggled: false,
                    sortable: true,
                    pin: false,
                    width: 'w-24',
                },
                {
                    title: 'Pickup post code',
                    key: 'pickup_postcode',
                    toggled: false,
                    sortable: true,
                    pin: false,
                    width: 'w-16',
                },
                {
                    title: 'Pickup country',
                    key: 'pickup_countrycode',
                    toggled: false,
                    sortable: true,
                    pin: false,
                    width: 'w-12',
                },
                {
                    title: 'Mode',
                    key: 'transport_mode',
                    toggled: true,
                    width: 'w-24',
                    sortable: true
                },
                {
                    title: 'To',
                    key: 'consignee_name',
                    toggled: true,
                    width: 'w-48',
                    sortable: true
                },
                {
                    title: 'To address',
                    key: 'consignee_address',
                    toggled: false,
                    width: 'w-48',
                    sortable: true
                },
                {
                    title: 'To city',
                    key: 'consignee_city',
                    toggled: false,
                    width: 'w-48',
                    sortable: true
                },
                {
                    title: 'To post code',
                    key: 'consignee_postcode',
                    toggled: false,
                    width: 'w-20',
                    sortable: true
                },
                {
                    title: 'To country',
                    key: 'consignee_countrycod',
                    toggled: false,
                    width: 'w-12',
                    sortable: true
                },
                {
                    title: 'Delivery name',
                    key: 'delivery_name',
                    toggled: false,
                    sortable: true,
                    pin: false,
                    width: 'w-48',
                },
                {
                    title: 'Delivery address',
                    key: 'delivery_address',
                    toggled: false,
                    sortable: true,
                    pin: false,
                    width: 'w-48',
                },
                {
                    title: 'Delivery city',
                    key: 'delivery_city',
                    toggled: false,
                    sortable: true,
                    pin: false,
                    width: 'w-24',
                },
                {
                    title: 'Delivery post code',
                    key: 'delivery_postcode',
                    toggled: false,
                    sortable: true,
                    pin: false,
                    width: 'w-16',
                },
                {
                    title: 'Delivery country',
                    key: 'delivery_countrycode',
                    toggled: false,
                    sortable: true,
                    pin: false,
                    width: 'w-12',
                },
                {
                    title: 'Carrier',
                    key: 'carrier_from_source',
                    toggled: true,
                    width: 'w-20',
                    sortable: true
                },
                {
                    title: 'Carrier service',
                    key: 'carrier_service_from_source',
                    toggled: false,
                    width: 'w-48',
                    sortable: true
                },
                {
                    title: 'Amount',
                    key: 'noofpackages',
                    toggled: false,
                    alignRight: false,
                    width: 'w-20',
                    sortable: true
                },
                {
                    title: 'Weight',
                    key: 'dimensions_weight',
                    toggled: true,
                    alignRight: false,
                    width: 'w-20',
                    sortable: true,
                },
                {
                    title: 'Volume',
                    key: 'dimensions_volume',
                    toggled: false,
                    alignRight: false,
                    width: 'w-20',
                    sortable: true
                },
                {
                    title: 'Loading metres',
                    key: 'dimensions_loadingmetres',
                    toggled: false,
                    alignRight: false,
                    width: 'w-20',
                    sortable: true
                },
                {
                    title: 'Price',
                    key: 'price_cat1',
                    toggled: true,
                    sortable: true,
                    pin: false,
                    width: 'w-20'
                },
                {
                    title: 'Status',
                    key: 'status',
                    toggled: true,
                    sortable: true,
                    pin: false,
                    width: 'w-16'
                },
                {
                    title: 'Purchase orders',
                    key: 'purchase_orders',
                    toggled: true,
                    width: 'w-24',
                    pin: false,
                    sortable: true
                },
                {
                    title: 'Goods types',
                    key: 'goods_types',
                    toggled: true,
                    width: 'w-24',
                    pin: false,
                    sortable: true
                },
                {
                    title: 'Direction',
                    key: 'direction',
                    toggled: true,
                    width: 'w-24',
                    pin: false,
                    sortable: true
                },
            ]

            this.insertAt(columns, 1, [
                {
                    title: 'Owner from source',
                    key: 'owner_from_source',
                    toggled: true,
                    sortable: true,
                    width: 'w-32'
                }
            ])

            columns.push({
                title: '',
                key: 'actions',
                toggled: true,
                sortable: false,
                overflow: true,
                width: 'w-24',
                tdClass: 'actions',
                pin: false,
            })

            this.togglePurchaseOrdersColumn()

            return columns
        },
        columnsToExport() {
            return [...this.columns,
                {
                    title: 'Initial EDD',
                    key: 'etadate',
                    toggled: false,
                    sortable: true,
                    pin: false,
                    width: 'w-32'
                }
            ];
        },
        adminRoute() {
            return this.$route.name === 'admin.shipments'
        },
        tableRef() {
            return this.adminRoute ? 'admin-consignments' : 'consignments'
        },
        visibleRows() {
            if (this.$refs[this.tableRef]) {
                return this.$refs[this.tableRef].$el.querySelectorAll(
                    'td input[type=checkbox]'
                )
            }

            return []
        },
        user() {
            return this.$store.getters['auth/user']
        },
        site() {
            return this.$store.getters['auth/site']
        },
        meta() {
            return this.$store.getters['auth/user'].meta
        },
        userSites() {
            return this.user.sitesFilter;
        },
        sitesLoading() {
            return this.$store.getters['auth/sitesLoading'];
        },
        showFreightModal() {
            return this.user.organization.booking_enabled;
        },
    },
    watch: {
        shipmentsFilter: {
            async handler(){
                if (!this.pageLoad) {
                    return;
                }

                if (this.isResetData) {
                    return;
                }

                await this.saveFilters();
            },
            deep: true
        },
        checkedRows() {
            if (this.checkedRows.length < this.visibleRows.length) {
                this.allRowsChecked = false
                this.checkedAll = false
            }

            if (!this.checkedRows.length) {
                this.checkedAll = false;
            }
        },
        filters: {
            async handler() {
                this.setFilterParams();

                if (this.shipmentsFilter && this.shipmentsFilter.filters) {
                    const newFilters = this.sendFilterValues();

                    if (JSON.stringify(newFilters) === JSON.stringify(this.shipmentsFilter.filters)) {
                        return;
                    }

                    this.shipmentsFilter.filters = newFilters;
                }
            },
            immediate: false,
            deep: true,
        },
        filterParams: {
            async handler(newValue, oldValue) {
                this.createdComponents = true;

                if (this.watchTrigger === 1) {
                    this.watchTrigger = 0;

                    return;
                }

                if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
                    await this.fetchFilterOptions();
                    await this.clearCheckedRows();
                }
            },
            immediate: false,
            deep: true,
        },
        statuses() {
            this.filters.status = this.statuses?.map(item => item.value) || null;
        },
        exception_details() {
            this.filters.exception_details = this.exception_details?.map(item => item.value) || null;
        },
        exception_at() {
            this.filters.exception_at = this.exception_at?.map(item => item.value) || null;
        },
        owner_site_from_source() {
            this.filters.owner_site_from_source = this.owner_site_from_source.length
                ? this.owner_site_from_source?.map(item => item.key) || null
                : this.userSites?.map(item => item.key) || null;
        },
        carrier_ids() {
            this.filters.carrier_ids = this.carrier_ids?.map(item => item.id) || null;
        },
        carrier_service_ids() {
            this.filters.carrier_service_ids = this.carrier_service_ids?.map(item => item.id) || null;
        },
        carrier_service_id() {
            this.filters.carrier_service_name = this.carrier_service_id?.map(item => item)  || null;
        },
        transport_mode() {
            this.filters.transport_mode = this.transport_mode?.map(item => {
                if (item === 'UNDEFINED') {
                    return '';
                }

                if (item === 'NULL') {
                    return 'null';
                }

                return item;
            })
        },
        userSites() {
            this.options['owner_site_from_source'] = this.userSites;

            const result = this.owner_site_from_source.filter(item => {
                return this.userSites.some(i => item.id === i.id);
            });

            if (result.length) {
                this.owner_site_from_source = result;
            } else {
                this.owner_site_from_source = [];
            }
        },
    },
    async created() { 
        this.filters.owner_site_from_source = this.userSites?.map(item => item.key) || null;
        
        await this.initFilters();

        const isFiltersFromUrl = await this.initFiltersFromUrl();

        if (!isFiltersFromUrl) {
            await this.initFilterValues();
        }

        this.fetchQueries();
        this.initDictionary();

        this.fetchOrganizations()
    },

    methods: {
        updateSortKey(value) {
            if (!this.shipmentsFilter) {
                return;
            }

            this.shipmentsFilter.sort_key = value;
        },

        updateSortOrder(value) {
            if (!this.shipmentsFilter) {
                return;
            }
            
            this.shipmentsFilter.sort_order = value;
        },

        updatedColumns(value) {
            if (!this.shipmentsFilter) {
                return;
            }

            this.shipmentsFilter.table_settings = value;
        },

        updatedPageSize(value) {
            if (!this.shipmentsFilter) {
                return;
            }

            this.shipmentsFilter.count_rows = value;
        },

        updatedPage(value) {
            this.shipmentsFilter.pagination = value;
        },
        initFiltersFromUrl() {
            const values = this.$route.query.filterParams;

            if (!values) {
                return false;
            }   

            this.filters.source = null;

            const valuesPrepare = values.split('__')
                .map(item => {
                    const key = item.slice(item.indexOf('[') + 1, item.indexOf(']'));
                    const value = item.slice(item.indexOf('=') + 1);

                    return { key, value };
                })
                .map(item => {
                    const value = item;

                    if (item.key === 'date_between' || item.key === 'etd_date_between' || item.key === 'eta_date_between') {
                        if (!item.value.includes('20')) {
                            value.value = item.value;
                        } else {
                            value.value = {
                                startDate: moment(new Date(item.value.slice(0, item.value.indexOf(',')))),
                                endDate: moment(new Date(item.value.slice(item.value.indexOf(',') + 1))),
                            }
                        }
                    }

                    if (value.value === '0' || value.value === 1) {
                        value.value = +value.value;
                    }

                    if (item.key === 'date_between') {
                        value.key = 'range';
                    }

                    return value;
                })

            const allFilters = [...this.filterHeader, ...this.filterSelects, ...this.filterSwitch];
            
            const filters = allFilters.filter(item => { return item.filter });
            const filtersCustom = allFilters.filter(item => { return !item.filter });

            filters.forEach(item => {
                const isSetup = valuesPrepare.find(key => { return item.model === key.key });
                
                if (isSetup) {
                    this.filters[isSetup.key] = isSetup.value;
                }
            });

            filtersCustom.forEach(item => {
                const isSetup = valuesPrepare.find(key => { return item.model === key.key });

                if (isSetup) {
                    this[isSetup.key] = JSON.parse(isSetup.value);
                }
            });

            const result = this.owner_site_from_source
                .filter(item => this.userSites.some(i => item.id === i.id))
                .map(item => item.key);

            if (result.length) {
                this.owner_site_from_source = result;
            } else {
                this.owner_site_from_source = [];
            }

            return true;
        },
        shareFilterLink() {
            const filterParams = Object.assign({}, this.filterParams);

            if (filterParams[`filter[transport_mode_from_source]`]) {
                filterParams[`filter[transport_mode_from_source]`] = JSON.stringify(this.transport_mode_from_source)
            }

            if (filterParams[`filter[carrier_service_ids]`]) {
                filterParams[`filter[carrier_service_ids]`] = JSON.stringify(this.carrier_service_ids);
            }

            if (filterParams[`filter[carrier_ids]`]) {
                filterParams[`filter[carrier_ids]`] = JSON.stringify(this.carrier_ids);
            }

            if (filterParams[`filter[owner_site_from_source]`]) {
                filterParams[`filter[owner_site_from_source]`] = JSON.stringify(this.owner_site_from_source)
            }

            if (filterParams[`filter[exception_details]`]) {
                filterParams[`filter[exception_details]`] = JSON.stringify(this.exception_details);
            }

            if (filterParams[`filter[investigated_by]`]) {
                filterParams[`filter[investigated_by]`] = JSON.stringify(this.investigated_by);
            }

            if (filterParams[`filter[status]`]) {
                delete filterParams[`filter[status]`];

                filterParams[`filter[statuses]`] = JSON.stringify(this.statuses);
            }

            const filters = serializeParams(filterParams);

            const currentQuery = this.$route.query;

            delete currentQuery.filters;

            const result = `${document.location.href}&filterParams=${filters.replaceAll('&', '__')}`;

            navigator.clipboard.writeText(result)
                .then(() => {
                    this.$snotify.success('Link copied to clipboard');
                })
                .catch(err => {
                    console.log('Something went wrong', err);
            });
        },
        async removeQuery(query) {
            await axios.delete('/api/me/filters/' + query.id);

            const result = await this.fetchQueries();

            if (!result.length) {
                this.showLoadQueryModal = false;
            }
        },
        rowCheckedAll() {
            if (this.checkedAll) {
                this.rowChecked('checkAllRows');

                return;
            }

            this.rowChecked('uncheckAllRows');
        },
        generateFilter(item) {
            if (!item.filter) {
                return this.vue;
            }

            return this.vue['filters'];
        },
        async initDictionary() {
            const { data: { data } } = await axios.get(this.$apiUrl.dictionary)

            this.dictionary = data;

            this.props_exception_details = data.exception_details.map(item => {
                return {
                    label: item.label,
                    id: item.value,
                    active: true,
                    single_only: true,
                };
            });
        },
        updateDashboardItem(value) {
            this.resetData()

            this.has_exception = 1
            this.filters.has_exception = 1

            this.statuses = [value]
            this.filters.status = this.statuses.map(item => { return item.value })

            this.filters.date_between = 'all'
        },

        insertAt(array, index, elements) {
            array.splice(index, 0, ...elements)
        },

        async toExcel(columnsParam) {
            const consignments = this.checkedRows.map(i => i.id).join(',')

            const columns = columnsParam.map(item => {
                return {
                    title: item.title,
                    key: item.key,
                }
            });

            replacePriceExportColumn(columns);
            
            const consignmentsFilter = consignments ? `&filter[id]=${consignments}` : '';

            try {
                await axios.post(`${this.$apiUrl.consignments.export}?${serializeParams(this.filterParams)}${consignmentsFilter}`, { columns });

                this.$snotify.success('Your task is in queue. Check your mailbox in 5 minutes');
            } catch(error) {
                this.$snotify.error(error?.response?.data?.message);
            }

            this.showExportModal = false;
        },
        clearFilterOptions() {
            this.options = defaultFilterOptions()
        },
        clearFilter(filter) {
            this.filters[filter] = null
        },
        savedRows() {
            if (!this.user.meta) {
                return []
            }

            return this.user.meta.consignments
        },
        getTableRows(value) {
            this.tableRows = value;
        },
        closeContactModal() {
            this.contactModal = false
            this.contact.sent = false
            this.contact.subject = ''
            this.contact.message = ''
            this.contact.withError = false
        },
        showContactModal() {
            this.contactModal = true
        },
        clone(obj) {
            return JSON.parse(JSON.stringify(obj))
        },
        setDefaults() {
            this.filters = Object.assign({}, this.defaults.filters);
            this.filters.owner_site_from_source = [this.userSites];
            this.filters.range = 'last_month';
            this.loadedRange = this.filters.range
            this.statuses = [];
            this.exception_details = [];
            this.exception_at = [];
            this.carrier_ids = [];
            this.owner_site_from_source = [];
            this.carrier_service_ids = [];
            this.transport_mode = [];
            this.carrier_service_id = []
            this.order_no = '';
            this.to_postcode = '';
            this.from_postcode = '';
        },
        async resetData(confirm = true) {
            this.isResetData = true;

            this.setDefaults()

            const query = {
                filters: this.clone(this.defaults.filters)
            }

            this.datesTrigger++;
            this.$store.commit('shipments/SET_QUERY', { query })
            
            if (confirm) { 
                this.$snotify.success('Form cleared')
            }

            await this.saveFilters();

            this.isResetData = false;
        },
        parseRangeDate(range) {
            if (typeof this.filters[range] === 'string') {
                return this.filters[range];
            }

            if (this.filters[range] && !this.filters[range].startDate) {
                return '';
            }

            return this.filters[range].startDate.format('YYYY-MM-DD') + ',' + this.filters[range].endDate.format('YYYY-MM-DD');
        },
        async setFilterParams() {
            this.loading = true;
            let params = {
                'filter[date_between]': this.parseRangeDate('range'),
            }

            if (this.parseRangeDate('etd_date_between')) {
                params['filter[etd_date_between]'] = this.parseRangeDate('etd_date_between');
            }

            if (this.parseRangeDate('eta_date_between')) {
                params['filter[eta_date_between]'] = this.parseRangeDate('eta_date_between');
            }

            let keys = Object.keys(this.filters);


            keys.forEach(filter => {
                if (filter === 'include_shared' && !this.filters[filter]) {
                    return
                }
                
                if (['egen_enabled', 'include_shared'].includes(filter)) {
                    params[`filter[${filter}]`] = this.filters[filter];

                    return;
                }

                if (filter === 'carrier_ids') {
                    const egenId = this.carrier_ids?.find(carrier => carrier.name === 'Egen transportör')?.id;

                    const egenFilterModel = this.filterSwitch.find(filter => filter.model === 'egen_enabled');

                    if (this.filters[filter]?.includes(egenId)) {
                        this.filters['egen_enabled'] = true;
                        params['filter[egen_enabled]'] = true;

                        egenFilterModel.disabled = true;
                    } else {
                        egenFilterModel.disabled = false;
                    }
                }

                if (this.filters[filter] && filter !== 'range' && filter !== 'eta_date_between' && filter !== 'etd_date_between') {

                    if (Array.isArray(this.filters[filter]) && !this.filters[filter].length) {
                        return;
                    }

                    if (filter === 'direction') {
                        params['filter[' + filter + ']'] = this.filters[filter]?.map(val => val.id);

                        return;
                    }

                    if (filter === 'source') {
                        params['filter[' + filter + ']'] = this.filters[filter]?.map(val => val.value);
                        
                        return;
                    }

                    params['filter[' + filter + ']'] = this.filters[filter].value ? this.filters[filter].value : this.filters[filter]
                }
            })

            this.filterParams = params
            localStorage.filterParams = JSON.stringify(params)

            this.loading = false;
        },
        rowChecked(row) {
            if (row === 'checkAllRows') {
                this.checkedRows = this.$refs[this.tableRef].table.visibleRows.slice();
                this.$refs[this.tableRef].table.checkedRows = this.$refs[this.tableRef].table.visibleRows.slice().map(item => {
                    return item.uuid
                });

                return
            }

            if (row === 'uncheckAllRows') {
                this.checkedRows = []
                this.$refs[this.tableRef].table.checkedRows = [];
                return
            }

            if (this.checkedRows.indexOf(row) !== -1) {
                this.checkedRows.splice(this.checkedRows.indexOf(row), 1)
            } else {
                this.checkedRows.push(row)
            }
        },
        rowClicked(row) {
            this.$store.dispatch('shipments/setTable', {
                filters: {
                    ...this.filters,
                },
                oFilters: {
                    vmStatuses: this.statuses,
                    vmException_details: this.exception_details,
                    vmException_at: this.exception_at,
                    vmCarrier_service_id: this.carrier_service_id,
                    order_no: this.order_no,
                    to_postcode: this.to_postcode,
                    by_reference: this.filters.by_reference,
                    from_postcode: this.from_postcode,
                },
                options: this.options,
                columns: this.tableRows,
            });

            this.$router.push({
                name: 'transport.shipments.detail',
                params: {
                    uuid: row.data.uuid
                }
            })
        },
        formatDate(date) {
            return moment(date).format('YYYY-MM-DD')
        },
        onClose() {
            this.toggleTableTools = false
        },
        parseTransportService(service) {
            const value = [
                service.first_transport_service_setting,
                service.second_transport_service_setting,
                service.third_transport_service_setting,
                service.fourth_transport_service_setting ,
            ];

            const result = value.filter(item => { return item });

            return result.join(', ');
        },
        refreshTableSilent() {
            this.$refs[this.tableRef].table.fetchRowsSilent()
        },
        serializeFilterParams(params) {
            if (params['filter[carrier_ids]']) {
                params['filter[carrier_ids]'] = params['filter[carrier_ids]'].map(carrier => typeof carrier === 'object' ? carrier.id : carrier);
            }

            if (params['filter[exception_details]']) {
                params['filter[exception_details]'] = params['filter[exception_details]'].map(detail => typeof detail === 'object' ? detail.value : detail);
            }

            return serializeParams(params);
        },
        async fetchFilterOptions() {
            if (!Object.keys(this.filterParams).length) {
                return;
            }

            const filterParams = Object.assign({}, this.filterParams);

            if (this.user.level != 'super') {
                filterParams[`filter[owner_from_source]`] = this.user.organization.key;
            }

            const { data } = await axios.get(this.$apiUrl.filterOptions + this.serializeFilterParams(filterParams))
            let response = data.data
            let keys = Object.keys(this.options)

            this.owner_list = data.data.owner_from_source || [];

            this.clearFilterOptions();

            keys.forEach(option => {
                let responseOption = option === 'transport_mode' ? response['transportservice_transportmode'] : response[option];
                responseOption = responseOption?.filter(option => option);

                if (responseOption) {
                    if (option === 'carrier_from_source' || option === 'carrier_service_from_source') {
                        return;
                    }

                    if (option === 'transport_mode') {
                        this.options[option] = response['transportservice_transportmode'].map(item => {
                            if (item === null) { item = 'NULL'; }

                            if (item === undefined || item === '') { item = 'UNDEFINED'}

                            return item;
                        });

                        return;
                    }

                    if (['consignee_name', 'consignor_name'].includes(option)) {
                        responseOption = responseOption.filter(optionItem => (
                            optionItem.toLowerCase().indexOf('vchain') === -1 && optionItem.toLowerCase() !== 'egen transportör'
                        ));
                    }

                    this.options[option] = responseOption;
                }
            })

            const filteredCarriers = data.data.carrier_data.filtered_carriers;

            this.options['carrier_ids'] = data.data.carrier_data.all_carriers;
            this.options['owner_site_from_source'] = this.userSites;

            this.options['is_not_external'] = filterOptions.isNotExternal;
            this.options['source'] = filterOptions.source;

            if (!filteredCarriers.length) {
                this.options['carrier_service_ids'] = [];
                this.options['transport_mode'] = [];
            }

            if (filteredCarriers.length) {
                this.options['carrier_service_ids'] = filteredCarriers.map(item => {
                    return {
                        name: item.carrier.name,
                        items: item.services,
                    }
                });

                const transportModesUniq = filteredCarriers.filter(item => {
                    const result = item?.transport_mode?.filter(mode => { return mode });

                    if (result && result.length) {
                        return item;
                    }
                });

                if (transportModesUniq.length) {
                    this.options['transport_mode'] = transportModesUniq.map(item => {
                        return {
                            name: item.carrier.name,
                            items: item?.transport_mode?.filter(item => { return item }),
                        }
                    });
                }
            }
        },
        async fetchAllFilterOptions() {
            this.loadingFilters = true

            const filterParamsValues = this.serializeFilterParams(this.filterParams);

            if (!filterParamsValues) {
                return;
            }

            const { data } = await axios.get(this.$apiUrl.filterOptions + filterParamsValues);

            this.owner_list = data.data.owner_from_source || [];

            this.loadingFilters = false

            let response = data.data
            let keys = Object.keys(this.options)

            keys.forEach(option => {
                if (response[option]) {
                    this.options[option] = response[option]
                }
            })
        },
        async loadQuery(query) {
            await this.resetData();

            let title = query.title
            let filters = Object.assign({}, query.data.filters);

            if (filters.etd_date_between.endDate) {
                filters.etd_date_between.endDate = moment(filters.etd_date_between.endDate);
                filters.etd_date_between.startDate = moment(filters.etd_date_between.startDate);
            }

            if (filters.eta_date_between.endDate) {
                filters.eta_date_between.endDate = moment(filters.eta_date_between.endDate);
                filters.eta_date_between.startDate = moment(filters.eta_date_between.startDate);
            }

            if (filters.range.endDate) {
                filters.range.endDate = moment(filters.range.endDate);
                filters.range.startDate = moment(filters.range.startDate);
            }

            this.statuses = filters.status;
            this.exception_details = filters.exception_details;
            this.exception_at = filters.exception_at;
            this.carrier_ids = filters.carrier_ids;
            this.owner_site_from_source = filters.owner_site_from_source;

            this.carrier_service_ids = filters.carrier_service_ids;
            this.transport_mode = filters.transport_mode;
            this.carrier_service_id = filters.carrier_service_name;
            this.order_no = filters.order_no;
            this.to_postcode = filters.to_postcode;
            this.by_reference = filters.by_reference;
            this.from_postcode = filters.from_postcode;
            

            if (filters.status && filters.status.length) {
                filters.status = filters.status.map(item => { return item.value });
            }

            if (filters.status && filters.status.length) {
                filters.status = filters.status.map(item => { return item.value });
            }

            if (filters.exception_details && filters.exception_details.length) {
                filters.exception_details = filters.exception_details.map(item => { return item.value });
            }

            if (filters.exception_at && filters.exception_at.length) {
                filters.exception_at = filters.exception_at.map(item => { return item.value });
            }

            if (filters.carrier_ids && filters.carrier_ids.length) {
                filters.carrier_ids = filters.carrier_ids.map(item => { return item.id });
            }

            if (filters.owner_site_from_source && filters.owner_site_from_source.length) {
                filters.owner_site_from_source = filters.owner_site_from_source.map(item => item.key);
            }

            if (filters.carrier_service_ids && filters.carrier_service_ids.length) {
                filters.carrier_service_ids = filters.carrier_service_ids.map(item => { return item.id });
            }

            if (filters.transport_mode && filters.transport_mode.length) {
                filters.transport_mode = filters.transport_mode.map(item => {
                    if (item === 'UNDEFINED') {
                        return '';
                    }

                    if (item === 'NULL') {
                        return 'null';
                    }

                    return item;
                });
            }

            if (filters.carrier_service_name && filters.carrier_service_name.length) {
                filters.carrier_service_name = filters.carrier_service_name.map(item => { return item });
            }

            this.filters = filters;

            this.loadedRange = this.filters.range;

            this.showLoadQueryModal = false;

            this.datesTrigger++;

            this.$snotify.success(`Query "${title}" loaded!`)
        },

        loadTemplate(index, template) {
            let title = template.title
            this.goods[index] = template.template_data

            this.showLoadTemplatesModal = false
            this.$snotify.success(`Template "${title}" loaded!`)
        },
        clearCheckedRows() {
            this.checkedRows = []
            this.$refs[this.tableRef].table.checkedRows = []
            this.checkedAll = false
        },
        async postSaveQuery() {
            this.savingQuery = true

            let title = this.saveQuery.title

            const filters = Object.assign({}, this.filters);

            const queryData = {
                filters
            }

            queryData.filters.status = this.statuses;
            queryData.filters.exception_details = this.exception_details;
            queryData.filters.exception_at = this.exception_at;
            queryData.filters.carrier_ids = this.carrier_ids;
            queryData.filters.owner_site_from_source = this.owner_site_from_source;
            queryData.filters.carrier_service_ids = this.carrier_service_ids;
            queryData.filters.transport_mode = this.transport_mode;
            queryData.filters.carrier_service_name = this.carrier_service_id;

            const query = prepareFiltersForSave(queryData.filters);

            const { data } = await axios.post(this.$apiUrl.filter.base, {
                data: queryData,
                query,
                title: title,
                category: filterCategory,
            })

            let queries = data.data
            this.$store.commit('shipments/FETCH_SAVED_QUERIES_SUCCESS', { queries })

            this.savingQuery = false
            this.showSaveQueryModal = false

            this.$snotify.success(`Query "${title}" saved!`)

            this.saveQuery.title = '';

            await this.fetchQueries();
        },
        async fetchQueries() {
            const { data } = await axios.get(`${this.$apiUrl.filter.base}?filter[category]=${filterCategory}`)
            this.loadedQueries = data.data

            return data.data;
        },
        async saveConsignment(uuid) {
            this.consignments = this.consignments.map(consignment => {
                if (consignment.id !== uuid) {
                    return consignment;
                }

                consignment.bookmarks.push(2);
            });
            
            this.$snotify.success('Consignment bookmarked!');
        },
        async saveConsignments() {
            await axios.post(this.$apiUrl.consignments.bookmarks, {
                ids: this.checkedRows.map(i => i.id),
            });

            this.$snotify.success('Consignments bookmarked!');

            this.clearCheckedRows();
            this.refreshTableSilent();
        },
        async removeSavedConsignments() {
            await axios.delete(this.$apiUrl.consignments.bookmarks, {
                data: {
                    ids: this.checkedRows.map(i => i.id),
                }
            });

            this.$snotify.success('Removed selected bookmarks!')

            this.refreshTableSilent();
            this.clearCheckedRows();
        },
        async fetchOrganizations() {
            let url = null

            if (this.user.level === 'super') {
                url = '?pageSize=1000&include=sites'
            }

            if (url != null) {
                const { data } = await axios.get(this.$apiUrl.organizations.base + url)
                this.organizations = data.data
            }
        },
        onStart(e) {
            this.asFilterDrag = true;

            const result = (e.item.className.indexOf('checkbox') + 1 || e.item.className.indexOf('switch') + 1);

            if (result) {
                this.hotGroup = 'b';

                return;
            }

            this.hotGroup = 'a';
        },
        clearSelectedSite() {
            this.clearFilterOptions()
            this.fetchAllFilterOptions()
        },

        togglePurchaseOrdersColumn() {
            if (this.$refs && this.$refs[this.tableRef] && this.$refs[this.tableRef].table) {
                for (const column of this.$refs[this.tableRef].table.columns) {
                    if (column.key === 'actions') {
                        column.tdClass = 'actions';
                        column.width = 'w-12';
                        column.pin = false;
                    }
                }
            }
        },
        onFilterControl() {
            this.copyFiltersDrag = {
                filterHeader: [...this.filterHeader],
                filterSelects: [...this.filterSelects],
                filterSwitch: [...this.filterSwitch]
            }

            this.asFilterControl = true;
            this.showAdvanced = true;
        },
        cancelFilterControl() {
            this.filterHeader = this.copyFiltersDrag.filterHeader;
            this.filterSelects = this.copyFiltersDrag.filterSelects
            this.filterSwitch = this.copyFiltersDrag.filterSwitch;

            this.asFilterControl = false;
            this.showAdvanced = false;
        },

        async saveFilterControl() {
            this.shipmentsFilter.filters = this.sendFilterValues();

            this.asFilterControl = false;
            this.showAdvanced = false;

            this.$snotify.success(`Filters saved successfully`);
        },

        async initBaseFilters(filters) {
            const result = {
                table_settings: this.columns,
                pagination: 1,
                count_rows: 50,
                sort_key: 'ordered_at',
                sort_order: '-1',
                filters: this.sendFilterValues(),
            }

            await axios.post(this.$apiUrl.users.filters, {
                ...filters,
                shipments: filters?.shipments || null,
                shipmentsClient: JSON.stringify(result),
                emissionsClient: filters?.emissionsClient || null,
            });

            this.shipmentsFilter = result;
        },

        async initFilters() {
            try {
                const { data: { data: { filters } } } = await axios.get(this.$apiUrl.whoami);

                // if (localStorage.getItem('readClientFiltersFromRemote') || !filters.shipmentsClient) {
                //     await this.initBaseFilters(filters);
                //     localStorage.setItem('readClientFiltersFromRemote', JSON.stringify(true));

                //     return;
                // }

                if (!filters.shipmentsClient) {
                    await this.initBaseFilters(filters);

                    return;
                }
                
                const result = JSON.parse(filters.shipmentsClient);

                const resultAllFilters = [
                    ...result.filters.filterHeader,
                    ...result.filters.filterSelects,
                    ...result.filters.filterSwitch,
                ];

                const allFilters = [
                    ...this.filterHeader,
                    ...this.filterSelects,
                    ...this.filterSwitch,
                ]

                if (resultAllFilters.length != allFilters.length) {
                    await this.initBaseFilters(filters);

                    return;
                }

                this.shipmentsFilter = result;
            } catch(error) {
                console.error(error);
            } finally {
                this.isLoadingTable = false;
            }
        },

        async saveFilters() {
            try {
                const { data: { data: { filters } } } = await axios.get(this.$apiUrl.whoami);

                await axios.post(this.$apiUrl.users.filters, {
                    ...filters,
                    shipments: filters?.shipments || null,
                    shipmentsClient: JSON.stringify(this.shipmentsFilter),
                    emissionsClient: filters?.emissionsClient || null,
                });

            } catch(error) {
                console.error(error);
            }
        },

        sendFilterValues() {
            const filters = {
                filterHeader: this.filterHeader,
                filterSelects: this.filterSelects,
                filterSwitch: this.filterSwitch,
            };

            for (let key in filters) {
                filters[key].forEach(item => {
                    if (item.filter) {
                        item.value = this.filters[item.model];

                        return;
                    }

                    item.value = this[item.model];
                });
            }

            return filters;
        },

        async initFilterValues() {
            const filters = this.shipmentsFilter.filters;

            if (!filters) {
                return;
            }

            if (!Cookies.get('shipments-init-values')) {
                Cookies.set('shipments-init-values', true)

                this.filterHeader = filters.filterHeader;
                this.filterSelects = filters.filterSelects;
                this.filterSwitch = filters.filterSwitch;

                this.resetData(true)

                return;
            }

            for (let key in filters) {
                filters[key].forEach(item => {
                    if (!item.value) {
                        return;
                    }

                    if (item.typeForm === 'date') {

                        if (item.value === 'all' || (item.value && !item.value.startDate)) {
                            this.filters[item.model] = item.value;
                        } else {
                            item.value.startDate = moment(new Date(item.value.startDate));
                            item.value.endDate = moment(new Date(item.value.endDate));

                            this.filters[item.model] = item.value;
                        }

                        return;
                    }

                    if (item.filter) {
                        this.filters[item.model] = item.value;

                        return;
                    }

                    this[item.model] = item.value;

                    if (item.template === 'filter-site-client') {
                        const result = item.value.filter(_item => {
                            return this.userSites.some(i => _item.id === i.id);
                        });

                        if (!result.length) {
                            this[item.model] = [];
                        } else {
                            this[item.model] = result;
                        }
                    }
                });
            }

            this.filterHeader = filters.filterHeader;
            this.filterSelects = filters.filterSelects;
            this.filterSwitch = filters.filterSwitch;
        }
    },

    async mounted() {
        this.initDragg = true;
    }
}
</script>

<style lang="scss">
.load-query-modal {
    .overflow-visible,
    .modal__body {
        overflow: visible;
    }
}

.google-sheets-trigger:hover {
    .google-sheets__wrapper {
        display: flex;
    }
}

.google-sheets {
    &-trigger {
        &:before {
            content: '';
            position: absolute;
            width: 34px;
            height: 6px;
            bottom: -6px;
            left: 0;
        }
    }

    &__wrapper {
        overflow-y: auto;
        position: absolute;
        top: calc(100% + 6px);
        left: 50%;
        transform: translateX(-50%);
        text-align: left;
        display: none;
        flex-direction: column;
        border: 1px solid #E6E6E6;
        background-color: white;
        box-shadow: 0 4px 35px 0px rgb(0 0 0 / 4%), 0 4px 6px 0px rgb(0 0 0 / 3%);
        max-width: 200px;
        max-height: calc(2.5rem * 5);
        min-width: 120px;
        z-index: 100;
    }

    &__item {
        text-align: left;
        padding: 12px;
        min-height: 2.5rem;
        display: flex;
        align-items: center;
        width: 100%;
        font-size: 0.75rem;

        &:hover {
            background-color: #20984B;
            color: white;
        }
    }
}

.shipments-switch-block {
    display: flex;
    flex-wrap: wrap;

    .switch,
    .checkbox {
        margin-right: 0 !important;
        min-width: 90px;
        max-width: calc(150px);
        width: 100%;
        margin-bottom: 1rem;
        height: 48px;
        margin-right: 1rem;

        .mr-5 {
            margin: 0 !important;
        }

        &.mt-2 {
            margin-top: 0;
        }
    }
}

.shipments-selects-block {
    .select,
    .multi-select,
    .multi-select-colors,
    .date,
    .input
    {
        margin-right: 1rem;
        width: calc((100% / 5) - 1rem);
        min-width: calc((100% / 5) - 1rem);
        max-width: calc((100% / 5) - 1rem);
    }
}

.shipments-hot-block {
    flex-wrap: wrap;
    margin-right: 1rem;
    .select,
    .multi-select,
    .multi-select-colors,
    .input,
    .date
    {
        margin-right: 1rem;
        width: calc((100% / 6.2) - 1rem);
        max-width: calc((100% / 6.2) - 1rem);
    }

    .switch,
    .checkbox {
        margin-right: 1rem;
        min-height: 40px;

        .mr-5 {
            margin-right: 0 !important;
        }

        &.mt-2, &.mt-5 {
            margin-top: 0;
            padding-top: 1rem;
        }
    }

    .item {
        margin-bottom: 1rem;
    }
}

.shipments-hot-panel {
    align-items: flex-start;

    &__bar {
        margin-top: 22px;
        display: flex;
        justify-content: flex-end;
    }
}

.shipments-filters-drag {
    .text-draggable {
        z-index: 1;
    }

    .sortable-chosen {
        background: transparent;
    }

    .shipments-drag-zone {
        position: relative;

        &:before {
            position: absolute;
            content: '';
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: rgba(0,0,0,1);
            opacity: 0;
            border-radius: 4px;
            transition: opacity .3s;
        }

        &--active {
            &:before {
                opacity: 0.03;
            }
        }
    }

    .shipments-hot-block.shipments-drag-zone,
    .shipments-selects-block.shipments-drag-zone,
    .shipments-switch-block-wrapper.shipments-drag-zone {
        &:before {
            top: -1rem;
            left: -1.5rem;
            width: calc(100% + 1.5rem);
            height: calc(100% + 1rem);
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
            border-top-right-radius: 0px;
        }
    }

    .shipments-selects-block.shipments-drag-zone {
        &:before {
            border-top-left-radius: 0;
            border-top-right-radius: 4px;
        }
    }

    .shipments-switch-block-wrapper.shipments-drag-zone {
        &:before {
            width: calc(100% + 2.5rem);
            left: -1rem;
        }
    }

    .shipments-hot-panel

    .checkbox, .switch {
        .toggle__line {
            transition: background .3s !important;
        }
    }

    &--active {
        .text-draggable {
            cursor: move;
        }

        .multi-select,
        .select,
        .multi-select-colors {
            .multiselect__tags {
                border-style: dashed;
            }
        }

        .date {
            input {
                border-style: dashed;
            }
        }

        .input input {
            border-style: dashed;
        }

        .checkbox, .switch {
            .toggle__line {
                border: 1px dashed rgba(0,0,0,0.1);
            }
        }
    }
}

.shipments-all-filters {
    &:before {
        position: absolute;
        left: 0;
        width: calc(100%);
        content: '';
        height: 1px;
        background: #E6E6E6;
    }
}

.page-shipments {
    .scroller {
        max-height: calc(100vh - (322px));
        min-height: 0;
    }

    .page-mode-table {
        box-shadow: none !important;
    }
}
</style>
